<template>
  <v-layout column wrap class="justify-space-between">
    <jo-header></jo-header>
    <v-layout class="row wrap white justify-center">
      <v-container class="layout row wrap justify-space-around align-center">
        <div class="flex xs12 md6 xl7 text-xs-center" data-aos="fade-up">
          <img src="@/assets/client/intro.png" class="not-found" :alt="$t('not_found.alt')" :title="$t('not_found.alt')">
        </div>
        <div data-aos="fade-down" class="flex xs10 md6 xl5 row wrap align-content-center pt-4 pb-5">
          <v-flex xs12>
            <h1 class="secondary--text s1">{{ $t("not_found.title") }}<span class="primary--text">,</span></h1>
          </v-flex>
          <v-flex xs12 class="pt-3 pb-4">
            <h3 class="secondary--text s3">{{ $t("not_found.text") }}</h3>
          </v-flex>
          <jo-store></jo-store>
        </div>
      </v-container>
    </v-layout>
    <jo-footer></jo-footer>
  </v-layout>
</template>

<script>

  import Store from './store'
  import JoHeader from "./header";
  import JoFooter from "./footer";

  export default {
    components: {
      'jo-store': Store,
      'jo-header': JoHeader,
      'jo-footer': JoFooter
    }
  }
</script>

<style scoped lang="scss">
  .not-found{
    margin: auto;
    width: 600px;
    height: auto;
  }
  .store{
    width: 150px;
  }
</style>