<template>
  <div class="primary">
    <jo-header></jo-header>
    <section :style="{'min-height': (window.height - 64)+'px'}" class="layout row align-center">
      <v-container>
        <v-layout wrap :class="{'pb-5 pr-3 pl-3': $vuetify.breakpoint.smAndDown}" class="row justify-center align-center">
          <div data-aos="fade-up" class="flex xs12 md5 layout column justify-center" :class="{'pr-5': $vuetify.breakpoint.mdAndUp, 'text-xs-center pt-3 pb-5': $vuetify.breakpoint.smAndDown}">
            <h1 class="background--text s1">{{$t('company.s1.title')}}</h1>
            <h3 class="accent--text s3 pt-4 pb-4">{{$t('company.s1.sub1')}}<br>{{$t('company.s1.sub2')}}</h3>
            <v-flex xs12>
              <a href="https://itunes.apple.com/us/app/justover/id1432230073?ls=1&mt=8" target="_blank" rel="nofollow" :title="$t('stores.appstore_alt')">
                <img src="@/assets/appstore-w.svg" class="store mr-3" :alt="$t('stores.appstore_alt')" :title="$t('stores.appstore_alt')">
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.oxequa.jo" target="_blank" rel="nofollow" :title="$t('stores.playstore_alt')">
                <img src="@/assets/playstore-w.svg" class="store" :title="$t('stores.playstore_alt')" :alt="$t('stores.playstore_alt')">
              </a>
            </v-flex>
          </div>
          <div data-aos="fade-down" data-aos-duration="1000" class="flex xs12 md7 layout row align-center justify-center" :class="{'pl-5': $vuetify.breakpoint.mdAndUp}">
            <img src="@/assets/company/intro.png" :alt="$t('company_meta_title')" :title="$t('company_meta_title')">
          </div>
        </v-layout>
      </v-container>
    </section>
    <section class="slice-w1 layout row align-center">
      <v-container class="index-4" :class="{'pa-5': $vuetify.breakpoint.lgAndUp, 'mt-5 mb-5': $vuetify.breakpoint.mdAndUp}">
        <v-layout wrap class="pt-5 pb-5 pr-3 pl-3 row justify-center">
          <div data-aos="zoom-in" class="flex xs12 layout wrap row justify-center pb-5">
            <v-flex xs12 layout row justify-center class="text-xs-center pb-3">
              <h2 class="s2 primary--text">{{$t('company.s2.title')}}</h2>
            </v-flex>
            <v-flex xs12 layout row justify-center class="text-xs-center">
              <h4 class="s4 accent--text fw-700">{{$t('company.s2.sub')}}</h4>
            </v-flex>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" class="flex xs12 md5 lg4 layout row wrap justify-center" :class="{'pb-5': $vuetify.breakpoint.smAndDown}">
            <v-flex xs12 layout row align-center>
              <img :src="require('../../assets/company/settings-1.png')" class="setup" :alt="$t('company.s2.first_alt')" :title="$t('company.s2.first_alt')">
            </v-flex>
            <v-flex xs12 class="layout row justify-center">
              <h5 class="fw-700 s4 text-xs-center text primary--text">
                {{$t('company.s2.first')}}
              </h5>
            </v-flex>
          </div>
          <div data-aos="fade-up" data-aos-duration="2000" class="flex xs12 md5 lg4 layout row wrap justify-center">
            <v-flex xs12 layout row align-center>
              <img :src="require('../../assets/company/settings-2.png')" class="setup" :alt="$t('company.s2.second_alt')" :title="$t('company.s2.second_alt')">
            </v-flex>
            <v-flex xs12 class="layout row justify-center">
              <h5 class="fw-700 s4 text-xs-center text primary--text">
                {{$t('company.s2.second')}}
              </h5>
            </v-flex>
          </div>
        </v-layout>
      </v-container>
    </section>
    <section class="slice-g3 layout row align-center">
      <v-container class="index-4" :class="{'pa-5': $vuetify.breakpoint.lgAndUp, 'mt-5 mb-5': $vuetify.breakpoint.mdAndUp}">
        <v-layout wrap class="pt-5 pb-5 pr-3 pl-3 row justify-center">
          <div data-aos="fade-right" class="flex xs12 md6 xl5 layout column justify-center" :class="{'pr-5': $vuetify.breakpoint.mdAndUp, 'text-xs-center': $vuetify.breakpoint.smAndDown}">
            <h2 class="background--text s1">{{$t('company.s3.title')}}</h2>
            <h3 class="accent--text s3 pt-4 pb-4">{{$t('company.s3.sub1')}} <br> {{$t('company.s3.sub2')}}</h3>
            <h5 class="background--text pb-4 s5 fw-700">{{$t('company.s3.text')}}</h5>
          </div>
          <div data-aos="fade-left" class="flex xs12 md6 xl5 layout row wrap align-center justify-center" :class="{'pl-5': $vuetify.breakpoint.mdAndUp}">
            <v-flex xs12 class="text-xs-center">
              <img :src="require('../../assets/company/cards.png')" class="tab" :alt="$t('company.s3.alt')" :title="$t('company.s3.alt')">
            </v-flex>
          </div>
        </v-layout>
      </v-container>
    </section>
    <section class="slice-w3 layout row align-center">
      <v-container class="index-4" :class="{'pa-5': $vuetify.breakpoint.lgAndUp, 'mt-5 mb-5': $vuetify.breakpoint.mdAndUp}">
        <v-layout wrap class="pt-5 pb-5 pr-3 pl-3 row justify-center">
          <div data-aos="fade-right" class="flex xs12 sm8 md6 xl5 layout row wrap align-center justify-center order-xs2 order-sm2 order-md1" :class="{'pr-5': $vuetify.breakpoint.mdAndUp}">
            <v-flex xs12 class="text-xs-center">
              <img :src="require('../../assets/company/insight.png')" class="tab" :alt="$t('company.s4.alt')" :title="$t('company.s4.alt')">
            </v-flex>
          </div>
          <div data-aos="fade-left" class="flex xs12 sm4 md6 xl5 layout column justify-center order-xs1 order-sm1 order-md-2" :class="{'pl-5': $vuetify.breakpoint.mdAndUp, 'text-xs-center': $vuetify.breakpoint.smAndDown}">
            <h2 class="primary--text s1">{{$t('company.s4.title')}}</h2>
            <h3 class="accent--text s3 pt-4 pb-4">{{$t('company.s4.sub')}}</h3>
            <h5 class="primary--text s5 pb-4 fw-700">{{$t('company.s4.text')}}</h5>
          </div>
        </v-layout>
      </v-container>
    </section>
    <section class="slice-g3 layout row align-center">
      <v-container class="index-4" :class="{'pa-5': $vuetify.breakpoint.lgAndUp, 'mt-5 mb-5': $vuetify.breakpoint.mdAndUp}">
        <v-layout wrap class="pt-5 pb-5 pr-3 pl-3 row justify-center">
          <div data-aos="fade-right" class="flex xs12 sm4 md6 xl5 layout column justify-center order-xs1 order-sm1 order-md-2" :class="{'pr-5': $vuetify.breakpoint.mdAndUp, 'text-xs-center': $vuetify.breakpoint.smAndDown}">
            <h2 class="background--text s1">{{$t('company.s5.title')}}</h2>
            <h3 class="accent--text s3 pt-4 pb-4">{{$t('company.s5.sub')}}</h3>
            <h5 class="background--text s5 pb-4 fw-700">{{$t('company.s5.text')}}</h5>
          </div>
          <div data-aos="fade-left" class="flex xs12 sm8 md6 xl5 layout row wrap align-center justify-center order-xs2 order-sm2 order-md1" :class="{'pl-5': $vuetify.breakpoint.mdAndUp}">
            <v-flex xs12 class="text-xs-center">
              <img :src="require('../../assets/company/news.png')" class="tab" :alt="$t('company.s5.alt')" :title="$t('company.s5.alt')">
            </v-flex>
          </div>
        </v-layout>
      </v-container>
    </section>
    <section class="slice-w3 layout row align-center">
      <v-container class="index-4" :class="{'pa-5': $vuetify.breakpoint.lgAndUp, 'mt-5 mb-5': $vuetify.breakpoint.mdAndUp}">
        <v-layout wrap class="pt-5 pb-5 pr-3 pl-3 row justify-space-around">
          <div data-aos="zoom-in" class="flex xs12 layout wrap row justify-center pb-5">
            <v-flex xs12 layout row justify-center class="text-xs-center pb-3">
              <h2 class="primary--text s2 pb-3">{{$t('company.s6.title')}}</h2>
            </v-flex>
            <v-flex xs12 layout row justify-center class="text-xs-center">
              <h4 class="accent--text fw-700 s3">{{$t('company.s6.sub')}}</h4>
            </v-flex>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" class="flex xs12 sm5 md3 lg3 layout row wrap justify-center" :class="{'pb-5': $vuetify.breakpoint.smAndDown}">
            <v-layout class="plan row wrap align-content-center">
              <v-flex xs12 class="title background--text secondary pt-2 pb-2 pr-3 pl-3"><h4 class="fw-700 s4">{{$t('company.s6.first')}}</h4></v-flex>
              <v-flex xs12 class="description-1 pt-2 pb-2 pr-3 pl-3 background--text"><h6 class="s5">{{$t('company.s6.first_sub')}}</h6></v-flex>
              <v-flex xs12 class="detail pt-2 pb-2 pr-3 pl-3 white layout wrap row">
                <v-flex xs6 class="pb-2">{{$t('company.s6.active_cards')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>1000</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.news')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>2/{{$t('company.s6.month')}}</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.news_push')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>0/{{$t('company.s6.month')}}</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.over_supported')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>1</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.over_included')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>1</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.multi_rewards')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>-</strong></v-flex>
              </v-flex>
            </v-layout>
          </div>
          <div data-aos="fade-up" data-aos-duration="2000" class="flex xs12 sm5 md3 lg3 layout row wrap justify-center" :class="{'pb-5': $vuetify.breakpoint.smAndDown}">
            <v-layout class="plan row wrap align-content-center">
              <v-flex xs12 class="title background--text accent pt-2 pb-2 pr-3 pl-3"><h4 class="fw-700 s4">{{$t('company.s6.second')}}</h4></v-flex>
              <v-flex xs12 class="description-2 pt-2 pb-2 pr-3 pl-3 background--text"><h6 class="s5">{{$t('company.s6.second_sub')}}</h6></v-flex>
              <v-flex xs12 class="detail pt-2 pb-2 pr-3 pl-3 white layout wrap row">
                <v-flex xs6 class="pb-2">{{$t('company.s6.active_cards')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>3000</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.news')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>2/{{$t('company.s6.month')}}</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.news_push')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>2/{{$t('company.s6.month')}}</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.over_supported')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>1</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.over_included')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>1</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.multi_rewards')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>{{$t('company.s6.yes')}}</strong></v-flex>
              </v-flex>
            </v-layout>
          </div>
          <div data-aos="fade-up" data-aos-duration="3000" class="flex xs12 sm5 md3 lg3 layout row wrap justify-center">
            <v-layout class="plan row wrap align-content-center">
              <v-flex xs12 class="title background--text primary pt-2 pb-2 pr-3 pl-3"><h4 class="fw-700 s4">{{$t('company.s6.third')}}</h4></v-flex>
              <v-flex xs12 class="description-3 pt-2 pb-2 pr-3 pl-3 background--text"><h6 class="s5">{{$t('company.s6.third_sub')}}</h6></v-flex>
              <v-flex xs12 class="detail pt-2 pb-2 pr-3 pl-3 white layout wrap row">
                <v-flex xs6 class="pb-2">{{$t('company.s6.active_cards')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>5000</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.news')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>4/{{$t('company.s6.month')}}</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.news_push')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>4/{{$t('company.s6.month')}}</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.over_supported')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>2</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.over_included')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>1</strong></v-flex>
                <v-flex xs6 class="pb-2">{{$t('company.s6.multi_rewards')}}</v-flex>
                <v-flex xs6 class="pb-2 text-xs-right"><strong>{{$t('company.s6.yes')}}</strong></v-flex>
              </v-flex>
            </v-layout>
          </div>
          <div data-aos="zoom-in" class="flex xs12 layout wrap row justify-center pt-5 mt-5">
            <v-flex xs12 layout row justify-center class="text-xs-center pb-3">
              <h2 class="secondary--text s2 pb-3">{{$t('company.s6.title-2')}}</h2>
            </v-flex>
            <v-flex xs12 layout row justify-center class="text-xs-center">
              <h4 class="primary--text fw-700 s3">{{$t('company.s6.sub-2')}}</h4>
            </v-flex>
            <v-flex xs12 class="text-xs-center pt-5">
              <router-link to="/support">
                <v-btn class="jbtn secondary background--text radius s4 fw-700">{{$t('company.s6.contact')}}</v-btn>
              </router-link>
            </v-flex>
          </div>
        </v-layout>
      </v-container>
    </section>
    <section class="primary relative index-4 layout row align-center">
      <v-container class="index-4" :class="{'pa-5': $vuetify.breakpoint.lgAndUp, 'mt-5 mb-5': $vuetify.breakpoint.mdAndUp}">
        <v-layout wrap class="pt-5 pb-5 pr-3 pl-3 row justify-center">
          <div data-aos="zoom-in" class="flex xs12 layout wrap row justify-center pb-5">
            <v-flex xs12 layout row justify-center class="text-xs-center pb-3">
              <h2 class="s1 background--text">{{$t('company.s7.title')}}</h2>
            </v-flex>
            <v-flex xs12 layout row justify-center class="text-xs-center">
              <h4 class="s3 accent--text fw-700">{{$t('company.s7.sub')}}</h4>
            </v-flex>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" class="flex xs12 md4 layout row wrap justify-center" :class="{'pb-5': $vuetify.breakpoint.smAndDown}">
            <v-flex xs12 layout row align-center>
              <img :src="require('../../assets/company/setup-1.png')" class="setup" :alt="$t('company.s2.first_alt')" :title="$t('company.s2.first_alt')">
            </v-flex>
            <v-flex xs12 class="layout row justify-center">
              <h5 class="fw-700 s4 text-xs-center text background--text">
                {{$t('company.s7.first')}}
              </h5>
            </v-flex>
          </div>
          <div data-aos="fade-up" data-aos-duration="2000" class="flex xs12 md4 layout row wrap justify-center" :class="{'pb-5': $vuetify.breakpoint.smAndDown}">
            <v-flex xs12 layout row align-center>
              <img :src="require('../../assets/company/setup-2.png')" class="setup" :alt="$t('company.s2.second_alt')" :title="$t('company.s2.second_alt')">
            </v-flex>
            <v-flex xs12 class="layout row justify-center">
              <h5 class="fw-700 s4 text-xs-center text background--text">
                {{$t('company.s7.second')}}
              </h5>
            </v-flex>
          </div>
          <div data-aos="fade-up" data-aos-duration="3000" class="flex xs12 md4 layout row wrap justify-center">
            <v-flex xs12 layout row align-center>
              <img :src="require('../../assets/company/setup-3.png')" class="setup" :alt="$t('company.s2.second_alt')" :title="$t('company.s2.second_alt')">
            </v-flex>
            <v-flex xs12 class="layout row justify-center">
              <h5 class="fw-700 s4 text-xs-center text background--text">
                {{$t('company.s7.third')}}
              </h5>
            </v-flex>
          </div>
        </v-layout>
      </v-container>
    </section>
    <jo-footer></jo-footer>
  </div>
</template>

<script>

  import Footer from '../footer'
  import Header from '../header'

  export default {
    components: {
      'jo-header': Header,
      'jo-footer': Footer
    },
    created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
      }
    },
    data () {
      return {
        window: {
          width: 0,
          height: 0,
          navbar: 0
        },
        options: {
          menu: '#menu',
          navigation: false,
          controlArrows: false,
          responsiveWidth: 1280,
          autoScrolling: false,
          sectionsColor: ['#4ABA5E', '#4ABA5E', '#FFF', '#4ABA5E', '#FFF', '#4ABA5E', '#FFF'],
          licenseKey: process.env.VUE_APP_FULLPAGE_KEY
        },
      }
    },
  }
</script>

<style scoped lang="scss">
  @media screen and (min-width: 1601px) {
    .setup{
      margin: auto;
      width: 375px;
    }
    .tab{
      width: 100%;
      max-width: 475px !important;
    }
    .start{
      margin: auto;
      width: 350px;
    }
  }
  @media screen and (max-width: 1600px) {
    .setup{
      margin: auto;
      width: 300px;
    }
    .tab{
      width: 100%;
      max-width: 400px !important;
    }
    .start{
      margin: auto;
      width: 250px;
    }
  }
  .tab-divider{
    border-top: 2px solid #EAEAEA;
    margin-top: -6px;
    width: 100%;
  }
  .text{
    width: 300px;
  }
  .divider{
    margin: auto;
    border-radius: 8px;
    border-top: 2px solid #F2F2F2;
  }
  .store{
    fill: black;
    width: 140px;
  }
  .partner{
    width: 200px;
  }
  .submit{
    margin-left: 10% !important;
  }
  .form{
    width: 100%;
  }
  .input{
    width: 80%;
    margin: auto;
    outline: none;
    border-radius: 8px;
    box-shadow: 0px 2px 4px -1px rgba(250,250,247,0.2), 0px 4px 5px 0px rgba(125,125,125,0.1), 0px 1px 10px 0px rgba(0,0,0,0.1);
  }
  .title{
    text-transform: uppercase;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .description-1{
    background: rgba(255,60,79,0.75);
  }
  .description-2{
    background: rgba(254,204,83,0.75);
  }
  .description-3{
    background: rgba(74,186,94,0.75);
  }
  .detail{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 2px solid #F2F2F2 !important;
    border-right: 2px solid #F2F2F2 !important;
    border-left: 2px solid #F2F2F2 !important;
  }
</style>

