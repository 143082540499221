import { render, staticRenderFns } from "./not-found.vue?vue&type=template&id=11d9ebda&scoped=true&"
import script from "./not-found.vue?vue&type=script&lang=js&"
export * from "./not-found.vue?vue&type=script&lang=js&"
import style0 from "./not-found.vue?vue&type=style&index=0&id=11d9ebda&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11d9ebda",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/Users/sergio/Desktop/justover-webpage/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VContainer,VFlex,VLayout})
