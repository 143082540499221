import Vue from 'vue'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './plugins/vuetify'
import App from './App.vue'
import Router from './router'
import { i18n } from '@/locale/index'

Vue.config.productionTip = false

new Vue({
  i18n,
  router: Router,
  created () {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
