<template>
  <div class="toolbar" :class="{'pr-3 pl-3': $vuetify.breakpoint.smAndDown, 'bt': border}">
    <v-layout class="container row wrap hidden-sm-and-down pt-2 pb-2">
      <v-flex>
        <router-link to="/support">
          <v-btn flat class="black--text radius lower mr-0 ml-0">{{$t('menu.support')}}</v-btn>
        </router-link>
        <router-link to="/privacy">
          <v-btn flat class="black--text radius lower mr-0 ml-0">{{$t('menu.privacy')}}</v-btn>
        </router-link>
      </v-flex>
      <v-flex layout row justify-end class="align-center">
        <a href="https://itunes.apple.com/us/app/justover/id1432230073" rel="nofollow" target="_blank" :title="$t('stores.appstore_alt')">
          <v-btn flat class="black--text radius lower mr-0 ml-0">{{$t('stores.appstore')}}</v-btn>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.oxequa.jo" target="_blank" rel="nofollow" :title="$t('stores.playstore_alt')">
          <v-btn flat class="black--text radius lower mr-0 ml-0">{{$t('stores.playstore')}}</v-btn>
        </a>
        <a href="tel:+3905831717480" :title="$t('menu.phone')">
          <v-btn flat class="primary white--text fw-700 lower radius mr-0">
            {{$t('menu.phone')}}
          </v-btn>
        </a>
      </v-flex>
    </v-layout>
    <v-layout class="container row wrap hidden-md-and-up">
      <v-flex xs12 layout row justify-end class="align-center bb">
        <router-link to="/company" class="layout row justify-center">
          <v-btn flat class="secondary--text mobile lower radius pr-3 pl-3">{{$t('menu.company')}}</v-btn>
        </router-link>
      </v-flex>
      <v-flex xs12 class="bb">
        <router-link to="/contacts" class="layout row justify-center">
          <v-btn flat class="black--text radius lower mobile">{{$t('menu.support')}}</v-btn>
        </router-link>
      </v-flex>
      <v-flex xs12 class="bb">
        <router-link to="/privacy" class="layout row justify-center">
          <v-btn flat class="black--text radius lower mobile">{{$t('menu.privacy')}}</v-btn>
        </router-link>
      </v-flex>
      <v-flex xs12 class="bb text-xs-center">
        <a href="https://itunes.apple.com/us/app/justover/id1432230073" rel="nofollow" target="_blank" :title="$t('stores.appstore_alt')">
          <v-btn flat class="black--text radius lower mr-0 ml-0">{{$t('stores.appstore')}}</v-btn>
        </a>
      </v-flex>
      <v-flex xs12 class="bb text-xs-center">
        <a href="https://play.google.com/store/apps/details?id=com.oxequa.jo" target="_blank" rel="nofollow" :title="$t('stores.playstore_alt')">
          <v-btn flat class="black--text radius lower mr-0 ml-0">{{$t('stores.playstore')}}</v-btn>
        </a>
      </v-flex>
      <v-flex xs12 class="text-xs-center">
        <a href="tel:+3905831717480" :title="$t('menu.phone')">
          <v-btn flat class="primary--text lower radius mobile">
            {{$t('menu.phone')}}
          </v-btn>
        </a>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    name: 'jo-footer',
    props: {
      border: {
        default: false,
        type: Boolean
      },
    }
  }
</script>

<style scoped lang="scss">
  .mobile{
    width: 100%;
  }
</style>