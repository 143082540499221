<template>
  <v-toolbar id="toolbar" :fixed=fixed flat class="pa-0 toolbar" :class="{'pr-3 pl-3': $vuetify.breakpoint.smAndDown, 'bb': border}">
    <v-layout class="container row pa-0">
      <v-toolbar-title class="layout row align-center">
        <router-link to="/" class="layout row align-center" :class="{'justify-space-between': $vuetify.breakpoint.smAndDown}">
          <img src="@/assets/logo.png" class="logo mr-3" :alt="$t('menu.name')" :title="$t('menu.name')">
        </router-link>
      </v-toolbar-title>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
    </v-layout>
  </v-toolbar>
</template>

<script>
  export default {
    name: 'jo-header',
    props: {
      fixed: {
        default: false,
        type: Boolean
      },
      border: {
        default: false,
        type: Boolean
      },
      title: {
        default: 'InMyOut',
        type: String
      }
    }
  }
</script>

<style lang="scss">
  .toolbar{
    background: #f5f5f5;
    .container{
      margin: auto;
      padding: 0;
    }
    .v-toolbar__content{
      padding: 0 !important;
    }
    .logo{
      width: 150px;
    }
    .radius{
      border-radius: 8px;
    }
    .active{
      padding: 0 2px;
      border-bottom: 3px solid #FF3C4F;
    }
  }
</style>
