import Vue from 'vue'
import axios from 'axios'
import VueI18n from 'vue-i18n'
import MetaInfo from 'vue-meta'
import VueAxios from 'vue-axios'
import VueJsonp from 'vue-jsonp'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(VueI18n)

Vue.use(MetaInfo)

Vue.use(VueJsonp)

Vue.use(VueAxios, axios)

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#8752f5',
    secondary: '#19b3ff',
    accent: '#FECC53',
    background: '#FFF',
  }
})
