<template>
  <div class="overflow-hidden">
    <jo-header border></jo-header>
    <section :style="{'min-height': (window.height - 64)+'px'}" class="background layout row align-center pt-5">
      <v-container>
        <v-layout wrap :class="{'pb-5 pr-3 pl-3': $vuetify.breakpoint.smAndDown}" class="row justify-center">
          <div data-aos="fade-up" class="layout flex xs12 md5 column justify-center" :class="{'pr-5': $vuetify.breakpoint.mdAndUp, 'text-xs-center pt-3 pb-5': $vuetify.breakpoint.smAndDown}">
            <h1 class="primary--text s1">{{$t('home.s1.title')}}</h1>
            <h2 class="accent--text s3 pt-4 pb-4">{{$t('home.s1.sub')}}</h2>
            <a href="http://app.inmyout.com">
              <v-btn large color="secondary" class="radius fw-700" :class="{'ml-0': $vuetify.breakpoint.mdAndUp}">{{$t('home.s6.call-1')}}</v-btn>
            </a>
          </div>
          <div data-aos="fade-down" data-aos-duration="1000" class="layout flex xs12 md7 row align-center justify-center" :class="{'pl-5': $vuetify.breakpoint.mdAndUp}">
            <img src="@/assets/client/intro.png" :alt="$t('home_meta_title')" :title="$t('home_meta_title')">
          </div>
        </v-layout>
      </v-container>
    </section>
    <section class="slice-g1 layout row align-center pt-5">
      <v-container :class="{'pa-5': $vuetify.breakpoint.lgAndUp, 'mt-5 mb-5': $vuetify.breakpoint.mdAndUp}" class="index-2">
        <v-layout wrap :class="{'pt-5 pb-5 pr-3 pl-3': $vuetify.breakpoint.smAndDown}" class="row justify-center">
          <div data-aos="fade-down" data-aos-duration="1000" class="layout flex xs12 md6 row align-center justify-center" :class="{'pl-5': $vuetify.breakpoint.mdAndUp}">
            <img src="@/assets/client/mobile.png" :alt="$t('home_meta_title')" :title="$t('home_meta_title')">
          </div>
          <div data-aos="fade-up" class="flex xs12 md6 xl5 layout column justify-center order-xs1 order-sm1 order-md-2" :class="{'pl-4': $vuetify.breakpoint.mdAndUp, 'text-xs-center': $vuetify.breakpoint.smAndDown}">
            <h2 class="background--text s1">{{$t('home.s2.title')}}</h2>
            <h3 class="background--text pt-4 s3 pb-4">{{$t('home.s2.sub')}}</h3>
            <h5 class="background--text s5 pb-4 fw-700">{{$t('home.s2.text')}}</h5>
          </div>
        </v-layout>
      </v-container>
    </section>
    <section class="slice-w3 layout row align-center pt-5 pb-5">
      <v-container :class="{'pa-5': $vuetify.breakpoint.lgAndUp, 'mt-5 mb-5': $vuetify.breakpoint.mdAndUp}" class="index-4">
        <v-layout wrap :class="{'pb-5 pr-3 pl-3': $vuetify.breakpoint.smAndDown}" class="row justify-center">
          <div data-aos="fade-up" class="layout flex xs12 md5 column justify-center" :class="{'pr-5': $vuetify.breakpoint.mdAndUp, 'text-xs-center pt-3 pb-5': $vuetify.breakpoint.smAndDown}">
            <h2 class="primary--text s1">{{$t('home.s3.title')}}</h2>
            <h3 class="accent--text s3 pt-4 pb-4">{{$t('home.s3.sub')}}</h3>
            <h5 class="accent--text s5 pb-4 fw-700">{{$t('home.s3.text')}}</h5>
          </div>
          <div data-aos="fade-down" data-aos-duration="1000" class="layout flex xs12 md7 row align-center justify-center" :class="{'pl-5': $vuetify.breakpoint.mdAndUp}">
            <img src="@/assets/client/activities.png" :alt="$t('home_meta_title')" :title="$t('home_meta_title')">
          </div>
        </v-layout>
      </v-container>
    </section>
    <section class="slice-g3 layout row align-center pt-5">
      <v-container :class="{'pa-5': $vuetify.breakpoint.lgAndUp, 'mt-5 mb-5': $vuetify.breakpoint.mdAndUp}" class="index-4">
        <v-layout wrap :class="{'pb-5 pr-3 pl-3': $vuetify.breakpoint.smAndDown}" class="row justify-center">
          <div data-aos="fade-down" data-aos-duration="1000" class="layout flex xs12 md7 row align-center justify-center" :class="{'pr-5': $vuetify.breakpoint.mdAndUp}">
            <img src="@/assets/client/manage.png" :alt="$t('home_meta_title')" :title="$t('home_meta_title')">
          </div>
          <div data-aos="fade-left" class="layout flex xs12 md5 column justify-center" :class="{'pl-5': $vuetify.breakpoint.mdAndUp, 'text-xs-center pt-3 pb-5': $vuetify.breakpoint.smAndDown}">
            <h2 class="background--text s1">{{$t('home.s4.title')}}</h2>
            <h3 class="background--text pt-4 s3 pb-4">{{$t('home.s4.sub')}}</h3>
            <h5 class="background--text s5 pb-4 fw-700">{{$t('home.s4.text')}}</h5>
            <div>
              <a href="http://app.inmyout.com">
                <v-btn large class="background primary--text radius fw-700" :class="{'ml-0': $vuetify.breakpoint.mdAndUp}">{{$t('home.s6.call-1')}}</v-btn>
              </a>
            </div>
          </div>
        </v-layout>
      </v-container>
    </section>
  </div>
</template>

<script>

  import Store from '../store'
  import Footer from '../footer'
  import Header from '../header'

  export default {
    components: {
      'jo-store': Store,
      'jo-header': Header,
      'jo-footer': Footer
    },
    created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
      }
    },
    data () {
      return {
        window: {
          width: 0,
          height: 0,
          navbar: 0
        },
        options: {
          menu: '#menu',
          navigation: false,
          controlArrows: false,
          responsiveWidth: 1280,
          autoScrolling: false,
          sectionsColor: ['#FFF','#FFF', '#8752f5', '#FFF', '#4ABA5E', '#FFF'],
          licenseKey: process.env.VUE_APP_FULLPAGE_KEY
        },
      }
    },
  }
</script>

<style scoped lang="scss">

  .background {
    background-image: url('~@/assets/client/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media screen and (min-width: 1601px) {
    .setup{
      margin: auto;
      width: 300px;
    }
    .tab{
      width: 100%;
      max-width: 475px !important;
    }
  }
  @media screen and (max-width: 1600px) {
    .setup{
      margin: auto;
      width: 250px;
    }
    .tab{
      width: 100%;
      max-width: 500px !important;
    }
  }
  .text{
    width: 300px;
  }
  .img{
    width: 650px;
    max-width: 100%;
  }
  .divider{
    margin: auto;
    border-radius: 8px;
    border-top: 2px solid #F2F2F2;
  }
  .store{
    fill: black;
    width: 140px;
  }
  .partner{
    width: 200px;
  }
  .submit{
    margin-left: 10% !important;
  }
</style>
