<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  metaInfo () {
    return {
      title: this.$route.meta.title + ' - InMyOut',
      titleTemplate: (titleChunk) => {
        return titleChunk
      },
      meta: [
        {'http-equiv': 'Content-Type', content: 'text/html', charset: 'utf-8'},
        {name: 'description', content: this.$route.meta.description},
        {name: 'viewport', content: 'width=device-width,initial-scale=1.0'},
        // Google
        {itemprop: 'name', content: this.$route.meta.title},
        {itemprop: 'description', content: this.$route.meta.description},
        // OpenGraph
        {property: 'og:title', content:  this.$route.meta.title},
        {property: 'og:site_name', content: 'InMyOut'},
        {property: 'og:type', content: 'website'},
        {property: 'og:description', content: this.$route.meta.description}
      ],
      htmlAttrs: {
        lang: 'it'
      },
      link: [
        {rel: 'icon', href: '/static/favicon.ico', type: 'image/x-icon'},
        {rel: 'canonical', href: 'https://inmyout.com'}
      ]
    }
  },
  data () {
    return {
      //
    }
  }
}
</script>

<style lang="scss">
  html, body, .application{
    letter-spacing: 0.05rem;
    font-family: 'Poppins', sans-serif !important;
    img{
      max-width: 100%;
      max-height: 500px;
    }
    a{
      text-decoration: none;
    }
    .jbtn{
      padding: 8px 16px !important;
      height: 60px;
    }
    .jgrey{
      background: #F5F5F5 !important;
    }
    .ba{
      border: 1px solid rgba(0,0,0,.0975) !important;
    }
    .baErr{
      border: 1px solid #FF3C4F;
    }
    .bt{
      border-top: 1px solid rgba(0,0,0,.0975) !important;
    }
    .bb{
      border-bottom: 1px solid rgba(0,0,0,.0975) !important;
    }
    .fw-500{
      font-weight: 500 !important;
    }
    .fw-600{
      font-weight: 600 !important;
    }
    .fw-700{
      font-weight: 700 !important;
    }
    .fw-800{
      font-weight: 800 !important;
    }
    .radius{
      border-radius: 8px;
    }
    .v-expansion-panel{
      box-shadow: none !important;
    }
    .radius-t{
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .radius-b{
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .grecaptcha-badge {
      visibility: hidden;
    }
    .index-1{
      z-index: 1;
    }
    .index-2{
      z-index: 2;
    }
    .index-3{
      z-index: 3;
    }
    .index-4{
      z-index: 4;
    }
    .relative{
      position: relative;
    }
    .theme--light.application{
      background: transparent !important;
    }
    .slice-g1{
      position: relative;
      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 140%;
        top: 0;
        background: #8752f5;
      }
    }
    .slice-g3{
      position: relative;
      &:before {
        content: "";
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 140%;
        top: 0;
        -webkit-transform: skewY(-8deg);
        transform: skewY(-8deg);
        background: #8752f5;
      }
    }
    .slice-w1{
      position: relative;
      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 140%;
        top: 0;
        -webkit-transform: skewY(-8deg);
        transform: skewY(-8deg);
        background: #FFF;
      }
    }
    .slice-w3{
      position: relative;
      &:before {
        content: "";
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 140%;
        top: 0;
        -webkit-transform: skewY(-8deg);
        transform: skewY(-8deg);
        background: #FFF;
      }
    }
    .lower{
      text-transform: inherit;
      &:first-letter{
        text-transform: inherit !important;
      }
    }
    .shadow{
      box-shadow: 0 2px 4px rgba(3,27,78,.06);
      transition: box-shadow .25s linear,-webkit-box-shadow .25s linear;
      &:hover{
        box-shadow: 0 10px 20px rgba(3,27,78,.1);
      }
    }
    .circle{
      width: 60px;
      height: 60px;
      border-radius: 50%;
      text-align: center;
      box-shadow: 6px 3px 5px -1px rgba(0, 0, 0, 0.05), 0px 6px 10px 0px rgba(0,0,0,0.05), 0px 1px 18px 0px rgba(0,0,0,0.05);
      span{
        text-align: center;
        font-size: 2rem;
        line-height: 4.4rem;
        font-weight: 600;
      }
    }
    @media screen and (max-width: 960px) {
      .s1{
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 3rem;
      }
      .s3{
        font-size: 1.8rem;
        line-height: 2.3rem;
        font-weight: 700;
      }
      .s2{
        font-size: 2.5rem;
        line-height: 3rem;
        font-weight: 700;
      }
      .s4{
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: 400;
      }
    }
    @media screen and (min-width: 961px) {
      .s1{
        font-weight: 700;
        font-size: 4rem;
        line-height: 5rem;
      }
      .s3{
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 700;
      }
      .s2{
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 700;
      }
      .s4{
        font-size: 1.5rem;
        line-height: 2.5rem;
        font-weight: 400;
      }
    }
    .s5{
      font-size: 1.30rem;
      font-weight: 600;
    }
    .s6{
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
</style>
