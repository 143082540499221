import { render, staticRenderFns } from "./home.vue?vue&type=template&id=2abd0601&scoped=true&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"
import style0 from "./home.vue?vue&type=style&index=0&id=2abd0601&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2abd0601",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/Users/sergio/Desktop/justover-webpage/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VBtn,VContainer,VLayout})
