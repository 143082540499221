import { render, staticRenderFns } from "./support.vue?vue&type=template&id=57a519c9&scoped=true&"
import script from "./support.vue?vue&type=script&lang=js&"
export * from "./support.vue?vue&type=script&lang=js&"
import style0 from "./support.vue?vue&type=style&index=0&id=57a519c9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57a519c9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/Users/sergio/Desktop/justover-webpage/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VExpansionPanel } from 'vuetify/lib'
import { VExpansionPanelContent } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardText,VContainer,VExpansionPanel,VExpansionPanelContent,VFlex,VIcon,VLayout,VProgressCircular})
