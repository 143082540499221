import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  it: require('./it.json'),
  en: require('./en.json')
}

export const i18n = new VueI18n({
  locale: 'it',
  messages
})
