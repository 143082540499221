import Vue from 'vue'
import Router from 'vue-router'
import {i18n} from '@/locale/index'
import Privacy from '@/components/privacy'
import Support from '@/components/support'
import NotFound from '@/components/not-found'
import ClientHome from '@/components/client/home'
import CompanyHome from '@/components/company/home'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'ClientHome',
      component: ClientHome,
      meta: {
        title: i18n.tc('home_meta_title'),
        description: i18n.tc('home_meta_description')
      }
    },
    {
      path: '/company',
      name: 'Company',
      component: CompanyHome,
      meta: {
        title: i18n.tc('company_meta_title'),
        description: i18n.tc('company_meta_description')
      }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy,
      meta: {
        title: i18n.tc('privacy_meta_title'),
        description: i18n.tc('privacy_meta_description')
      }
    },
    {
      path: '/support',
      name: 'Support',
      component: Support,
      meta: {
        title: i18n.tc('contact_meta_title'),
        description: i18n.tc('contact_meta_description')
      }
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: NotFound,
      meta: {
        title: i18n.tc('not_found_meta_title'),
        description: i18n.tc('not_found_meta_description')
      }
    },
    {
      path: '*',
      name: 'error',
      redirect: '/not-found',
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router
