<template>
  <v-layout column wrap class="justify-space-between">
    <jo-header :title="$t('menu.privacy')"></jo-header>
    <v-layout class="row wrap white justify-center pb-5">
      <v-flex xs12 primary class="pr-3 pl-3 pt-2 pb-2 mb-4">
        <v-container class="pa-0">
          <h3 class="background--text fw-700 s3">{{$t('privacy.title')}}</h3>
        </v-container>
      </v-flex>
      <v-container class="layout row wrap pa-0 mt-0 justify-center">
        <v-flex xs12 md10 class="layout row wrap pr-3 pl-3">
          <v-flex xs12>
            {{$t('privacy.s1_text')}}
          </v-flex>
          <v-flex xs12 class="mt-3">
            <strong>
              {{$t('privacy.s2_title')}}
            </strong>
          </v-flex>
          <v-flex xs12>
            {{$t('privacy.s2_text')}}
          </v-flex>
          <v-flex xs12 class="mt-3">
            <strong>
              {{$t('privacy.s3_title')}}
            </strong>
          </v-flex>
          <v-flex xs12>
            {{$t('privacy.s3_text')}}
          </v-flex>
          <v-flex xs12 class="mt-3">
            <strong>
              {{$t('privacy.s4_title')}}
            </strong>
          </v-flex>
          <v-flex xs12>
            {{$t('privacy.s4_text')}}
          </v-flex>
          <v-flex xs12 class="mt-3">
            <strong>
              {{$t('privacy.s5_title')}}
            </strong>
          </v-flex>
          <v-flex xs12>
            {{$t('privacy.s5_text')}}
          </v-flex>
          <v-flex xs12 class="mt-3">
            <strong>
              {{$t('privacy.s6_title')}}
            </strong>
          </v-flex>
          <v-flex xs12>
            {{$t('privacy.s6_text')}}
          </v-flex>
          <v-flex xs12 class="mt-3">
            <strong>
              {{$t('privacy.s7_title')}}
            </strong>
          </v-flex>
          <v-flex xs12>
            {{$t('privacy.s7_text')}}
          </v-flex>
        </v-flex>
      </v-container>
    </v-layout>
    <jo-footer border></jo-footer>
  </v-layout>
</template>

<script>

  import Header from './header'
  import Footer from './footer'

  export default {
    components: {
      'jo-header': Header,
      'jo-footer': Footer,
    },
  }
</script>
