<template>
  <v-layout column wrap class="justify-space-between">
    <jo-header :title="$t('menu.support')"></jo-header>
    <v-layout class="row wrap white justify-center">
      <v-flex xs12 primary class="pr-3 pl-3 pt-2 pb-2 mb-3">
        <v-container class="pa-0">
          <h3 class="background--text fw-700 s3">{{$t('contacts.title')}}</h3>
        </v-container>
      </v-flex>
      <v-container class="layout row wrap pa-0 mt-0 justify-center">
        <v-flex xs12 class="layout row wrap">
          <v-flex xs12 class="layout radius-b row wrap pr-3 pl-3 justify-center">
             <v-flex xs12 md6 xl7 class="layout row wrap pb-3">
               <v-flex xs12 class="pb-3">
                 <h4 class="secondary--text fw-700 s4">{{$t('contacts.description')}}</h4>
               </v-flex>
               <v-flex xs12 layout wrap row align-center>
                 <form layout row class="form" @submit.prevent="formSubmit()">
                   <v-flex xs12 layout row wrap justify-center>
                     <input name="NAME" type="text" :placeholder="$t('contacts.name')" v-model="name" class="input pa-3 mb-3" :class="{'baErr': nameErr}">
                   </v-flex>
                   <v-flex xs12 layout row wrap justify-center>
                     <input name="EMAIL" type="text" :placeholder="$t('contacts.email')" v-model="email" class="input pa-3 mb-3" :class="{'baErr': emailErr}">
                   </v-flex>
                   <v-flex xs12 layout row wrap justify-center>
                     <textarea name="MESSAGE" rows="6" class="input pa-3 mb-3 radius" v-model="message" :placeholder="$t('contacts.message')" :class="{'baErr': messageErr}"></textarea>
                   </v-flex>
                   <v-flex xs12 layout row wrap justify-start>
                     <vue-recaptcha
                             ref="invisibleRecaptcha"
                             @verify="onVerify"
                             @expired="onVerify"
                             size="invisible"
                             :sitekey="recaptcha">
                     </vue-recaptcha>
                     <v-btn flat type="submit" class="radius submit ma-0 grey--text fw-700" :class="{'primary white--text': validate, 'ba': !validate}">
                       <transition name="fade" mode="out-in">
                         <span v-if="!loading && !success">{{$t('contacts.send')}}</span>
                         <v-progress-circular indeterminate :width="2" :size="20" color="background" class="spinner" v-if="loading && !success"></v-progress-circular>
                         <div v-if="!loading && success" class="layout row align-center">
                           <v-icon class="mr-2 ml-0 white--text" right dark>check</v-icon>
                           <span class="white--text">{{$t('contacts.send_success')}}</span>
                         </div>
                       </transition>
                     </v-btn>
                   </v-flex>
                 </form>
               </v-flex>
             </v-flex>
             <v-flex xs12 md3 offset-md1 offset-xl1 xl2 :class="{'pt-3': $vuetify.breakpoint.smAndDown}">
               <h3 class="secondary--text fw-700 s4 pb-3">{{$t('contacts.resources-description')}}</h3>
               <v-flex class="no-btn xs12 ma-0 flat s6 mb-3">
                 <a href="/overview.pdf" target="_blank" class="layout row justify-space-between">
                   <v-icon color="primary" flat size="20">save_alt</v-icon>
                   Overview
                 </a>
               </v-flex>
               <v-flex class="no-btn xs12 ma-0 flat s6 mb-3">
                 <a href="/guideline.pdf" target="_blank" class="layout row justify-space-between">
                   <v-icon color="primary" flat size="20">save_alt</v-icon>
                   Brand manual
                 </a>
               </v-flex>
               <v-flex class="no-btn xs12 ma-0 flat s6">
                 <a href="tel:+3905831717480" :title="$t('menu.phone')" class="layout row justify-space-between">
                   <v-icon color="primary" flat size="20">phone</v-icon>
                   <span>{{ $t('menu.phone') }}</span>
                 </a>
               </v-flex>
             </v-flex>
             <v-flex xs12 class="text-xs-center">
               <img src="@/assets/partners/eppela.png" width="150px" class="pa-3">
               <img src="@/assets/partners/postepay.png" width="250px" class="pa-3">
               <img src="@/assets/partners/mastercard.png" width="200px" class="pa-3">
             </v-flex>
          </v-flex>
        </v-flex>
      </v-container>
    </v-layout>
    <v-layout class="row wrap white justify-center pb-5">
      <v-flex xs12 primary class="pr-3 pl-3 pt-2 pb-2 mb-3">
        <v-container class="pa-0">
          <h3 class="background--text fw-700 s3">{{$t('contacts.faq')}}</h3>
        </v-container>
      </v-flex>
      <v-container class="layout row wrap pa-0 mt-0 justify-center">
        <v-flex xs12 class="layout row wrap">
          <v-flex xs12 class="layout radius-b row wrap pr-3 pl-3 justify-center">
            <v-flex xs12 md10 class="pb-3">
              <h4 class="secondary--text fw-700 s4">{{$t('contacts.faq-description')}}</h4>
            </v-flex>
            <v-flex xs12 md10>
              <v-expansion-panel>
                <v-expansion-panel-content class="jgrey">
                  <div slot="header" class="fw-700 grey--text s5">{{$t('faq.1.title')}}</div>
                  <v-card>
                    <v-card-text>{{$t('faq.1.text')}}</v-card-text>
                  </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content class="jgrey">
                  <div slot="header" class="fw-700 grey--text s5">{{$t('faq.2.title')}}</div>
                  <v-card>
                    <v-card-text>{{$t('faq.2.text')}}</v-card-text>
                  </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content class="jgrey">
                  <div slot="header" class="fw-700 grey--text s5">{{$t('faq.3.title')}}</div>
                  <v-card>
                    <v-card-text>{{$t('faq.3.text')}}</v-card-text>
                  </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content class="jgrey">
                  <div slot="header" class="fw-700 grey--text s5">{{$t('faq.4.title')}}</div>
                  <v-card>
                    <v-card-text>{{$t('faq.4.text')}}</v-card-text>
                  </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content class="jgrey">
                  <div slot="header" class="fw-700 grey--text s5">{{$t('faq.5.title')}}</div>
                  <v-card>
                    <v-card-text>{{$t('faq.5.text')}}</v-card-text>
                  </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content class="jgrey">
                  <div slot="header" class="fw-700 grey--text s5">{{$t('faq.6.title')}}</div>
                  <v-card>
                    <v-card-text>{{$t('faq.6.text')}}</v-card-text>
                  </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content class="jgrey">
                  <div slot="header" class="fw-700 grey--text s5">{{$t('faq.8.title')}}</div>
                  <v-card>
                    <v-card-text>{{$t('faq.8.text')}}</v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-flex>
          </v-flex>
        </v-flex>
      </v-container>
    </v-layout>
    <jo-footer border></jo-footer>
  </v-layout>
</template>

<script>

  import Header from './header'
  import Footer from './footer'
  import VueRecaptcha from 'vue-recaptcha';

  export default {
    components: {
      VueRecaptcha,
      'jo-header': Header,
      'jo-footer': Footer,
    },
    data () {
      return {
        loading: false,
        success: false,
        validate: false,
        nameErr: false,
        emailErr: false,
        messageErr: false,
        name: null,
        email: null,
        message: null,
        recaptcha: null,
      }
    },
    created () {
      this.recaptcha = process.env.VUE_APP_RECAPTCHA
    },
    methods: {
      onVerify: function (response) {
        if(response !== null) {
          this.$jsonp('https://app.us20.list-manage.com/subscribe/post-json?u=76a954628b03b7b6f7c489674&id=8ff2da2551', {
            EMAIL: this.email,
            NAME: this.name,
            MESSAGE: this.message,
          })
          this.loading = false
          this.success = true
        }
      },
      formSubmit() {
        this.nameErr = false;
        this.emailErr = false;
        this.messageErr = false;
        this.validate = false;

        if(this.name && this.email && this.message) {
          this.validate = true;
          this.loading = true
          this.success = false
          this.$refs.invisibleRecaptcha.execute()
        } else {
          if (!this.name) {
            this.nameErr = true
          }
          if (!this.email) {
            this.emailErr = true
          }
          if (!this.message) {
            this.messageErr = true
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  a{
    color: inherit;
    &:hover{
      color: #4ABA5E;
    }
  }
  .form{
    width: 100%;
    max-width: 600px;
  }
  .no-btn{
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    text-align: left;
  }
  .input{
    width: 100%;
    margin: auto;
    outline: none;
    background: #FAFAFA;
    border-radius: 8px;
    box-shadow: 0px 2px 4px -1px rgba(250,250,247,0.2), 0px 4px 5px 0px rgba(125,125,125,0.1), 0px 1px 10px 0px rgba(0,0,0,0.1);
  }
</style>
